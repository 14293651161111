import React from "react";
import useApiRequest from "../../../hooks/use-api-request.js";
import Loader from "../../../common/loader.jsx";
import ContentCard from "../components/content-card.jsx";
import EditablePillList from "../../../components/editable-pill-list.jsx";
import Select from "../../../common/select.jsx";
import axios from "../../../requests/axios.js";
import DialogForm from "../../../components/dialog-form.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded.js";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded.js";
import EditRoundedIcon from "@mui/icons-material/EditRounded.js";
import useSpreadState from "../../../hooks/use-spread-state.js";
import { AppHeadingActions } from "../../../layout/parts/app-heading.jsx";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import GenericDeleteDialog from "../../components/dialogs/generic-delete-dialog.jsx";
import GenericRestoreDialog from "../../components/dialogs/generic-restore-dialog.jsx";
import { useNavigate } from "react-router-dom";

let RATING_OPTIONS = {
  mpaa: [],
  us_tv: [],
  bbfc: [],
};

let GENRE_OPTIONS = {
  imdb: [],
  freeview: [],
};

export default function SeriesTab({ guid }) {
  const [showCastCrewDialog, setShowCastCrew] = React.useState(false);
  const [showKeywordDialog, setShowKeywordDialog] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [isTrashing, setTrashing] = React.useState(false);
  const [isRestoring, setRestoring] = React.useState(false);

  const [form, updateForm, hardUpdateForm] = useSpreadState({});
  const { isLoading, response } = useApiRequest(`/api/series/${guid}/summary`);
  const [series, setSeries] = React.useState({});
  const [data, setData] = React.useState({});

  const navigate = useNavigate();

  React.useEffect(() => {
    if (response.data) {
      setSeries(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (series) {
      setData({ ...series, ...(isEditing ? form : {}) });
    }
  }, [form, series, isEditing]);

  const getActiveRating = React.useCallback(
    (scheme) => {
      if (!data.rating) {
        return null;
      }

      const rating = RATING_OPTIONS[scheme].filter((i) => i.value === data.rating[`${scheme}_rating_id`]);
      if (!rating.length) {
        return null;
      }

      return rating[0];
    },
    [data.rating],
  );

  const getActiveGenre = React.useCallback(
    (scheme) => {
      let genre = null;
      switch (scheme) {
        case "imdb_genre":
          if (data.imdb_genre?.value) {
            genre = GENRE_OPTIONS["imdb"].find((i) => i.value === data.imdb_genre?.value);
          }
          break;
        case "imdb_subgenre":
          var primaryGenre = getActiveGenre("imdb_genre");
          //  subgenre is linked to a primary genre
          if (!primaryGenre || !data.imdb_subgenre) {
            break;
          }

          genre = getActiveGenre("imdb_genre").subgenres.find((i) => i.value === data.imdb_subgenre?.value);
          break;
        case "freeview_genre":
          genre = GENRE_OPTIONS["freeview"].find((i) => i.value === data.freeview_genre?.value);
          break;
      }

      if (!genre) {
        return null;
      }

      return genre;
    },
    [data.imdb_genre, data.imdb_subgenre, data.freeview_genre],
  );

  const resetForm = React.useCallback(() => {
    hardUpdateForm({
      imdb_genre: series.genre?.imdb?.imdb_genre
        ? { label: series.genre.imdb.imdb_genre.label, value: series.genre.imdb.imdb_genre.value }
        : null,
      imdb_subgenre: series.genre?.imdb?.imdb_subgenre
        ? { label: series.genre.imdb.imdb_subgenre.label, value: series.genre.imdb.imdb_subgenre.value }
        : null,
      freeview_genre: series.genre?.freeview?.freeview_genre
        ? {
            label: series.genre.freeview.freeview_genre.label,
            value: series.genre.freeview.freeview_genre.value,
          }
        : null,
      summary: {
        long_summary: series.summary?.long_summary ?? "",
        short_summary: series.summary?.short_summary ?? "",
      },
    });
  }, [series, hardUpdateForm]);

  const cancelEditing = React.useCallback(() => {
    setEditing(false);
    resetForm();
  }, [resetForm]);

  // fill out dropdown list options
  React.useEffect(() => {
    if (!RATING_OPTIONS.length) {
      axios
        .get("/api/ratings")
        .then((resp) => {
          RATING_OPTIONS = resp.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!GENRE_OPTIONS.length) {
      axios
        .get("/api/genres")
        .then((resp) => {
          const imdbGenres = resp.data.imdb.map((genre) => ({
            label: genre.label,
            value: genre.value,
            subgenres: genre.subgenres.map((subgenre) => ({ label: subgenre.label, value: subgenre.value })),
          }));

          const freeviewGenres = resp.data.freeview.map((genre) => ({
            label: genre.label,
            value: genre.value,
          }));

          GENRE_OPTIONS = {
            imdb: imdbGenres,
            freeview: freeviewGenres,
          };
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  //  set default form values
  React.useEffect(() => {
    if (series) {
      updateForm({
        imdb_genre: series.genre?.imdb?.imdb_genre
          ? { label: series.genre.imdb.imdb_genre.label, value: series.genre.imdb.imdb_genre.value }
          : null,
        imdb_subgenre: series.genre?.imdb?.imdb_subgenre
          ? { label: series.genre.imdb.imdb_subgenre.label, value: series.genre.imdb.imdb_subgenre.value }
          : null,
        freeview_genre: series.genre?.freeview?.freeview_genre
          ? {
              label: series.genre.freeview.freeview_genre.label,
              value: series.genre.freeview.freeview_genre.value,
            }
          : null,
        summary: {
          long_summary: series.summary?.long_summary ?? "",
          short_summary: series.summary?.short_summary ?? "",
        },
      });
    }
  }, [series, updateForm]);

  if (isLoading) {
    return (
      <div className="content-page__loader">
        <Loader />
      </div>
    );
  }

  function onSaveEdits() {
    setSaving(true);

    const payload = { ...form };
    // remove subgenre from payload if not set
    if (!payload.imdb_subgenre) {
      delete payload.imdb_subgenre;
    }

    axios
      .patch(`/api/series/${series.series_guid}`, payload)
      .then((resp) => {
        toast.success("Series successfully updated");
        setEditing(false);
        setSeries(resp.data);
      })
      .catch((error) => {
        toast.error("Error attempting to update Series");
        console.error(error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function getSubgenresForGenre(genre) {
    if (!genre) {
      return [];
    }

    const foundGenre = GENRE_OPTIONS.imdb.find((genreItem) => genreItem.value === genre.value);
    if (!foundGenre) {
      return [];
    }

    return foundGenre.subgenres;
  }

  function openCastCrewDialog() {
    setShowCastCrew(true);
  }

  function closeCastCrewDialog() {
    setShowCastCrew(false);
  }

  function updateGenre(type, input) {
    switch (type) {
      case "imdb_genre":
        updateForm({
          imdb_genre: input,
          imdb_subgenre: null,
        });
        break;

      case "imdb_subgenre":
        updateForm({
          imdb_subgenre: input,
        });
        break;

      case "freeview_genre":
        updateForm({
          freeview_genre: input,
        });
        break;
    }
  }

  function updateCastCrew(key, value, index) {
    const newCastCrew = data.cast_crew.map((member, ccIndex) => {
      if (ccIndex === index) {
        member[key] = value;
      }

      return member;
    });

    updateForm({
      cast_crew: newCastCrew,
    });
  }

  function updateRating(label, event) {
    const newRating = event.value;
    updateForm({
      rating: {
        ...data.rating,
        [`${label}_rating_id`]: newRating,
      },
    });
  }

  function updateSummary(label, event) {
    const newSummary = event.target.value;
    updateForm({
      summary: {
        ...form.summary,
        [label]: newSummary,
      },
    });
  }

  function addCastCrew(castCrew) {
    updateForm({ cast_crew: [...data.cast_crew, castCrew] });
  }

  function removeCastCrew(index) {
    updateForm({ cast_crew: [...data.cast_crew.slice(0, index), ...data.cast_crew.slice(index + 1)] });
  }

  function addKeyword(keyword) {
    updateForm({
      keywords: [...data.keywords, keyword],
    });
  }

  function onKeywordRemove(index) {
    updateForm({ keywords: [...data.keywords.slice(0, index), ...data.keywords.slice(index + 1)] });
  }

  return (
    !isLoading &&
    series && (
      <React.Fragment>
        <div className="tabbed-content__actions">
          {isSaving ? (
            <Loader height={35} width={35} />
          ) : (
            <AppHeadingActions
              rightActions={[
                {
                  when: isEditing,
                  icon: SaveRoundedIcon,
                  balloonLabel: "Save",
                  onClick: onSaveEdits,
                },
                {
                  when: isEditing,
                  icon: CancelRoundedIcon,
                  balloonLabel: "Cancel",
                  onClick: cancelEditing,
                },
                {
                  when: !isEditing && series.series_active === 1,
                  icon: EditRoundedIcon,
                  balloonLabel: "Edit",
                  onClick: () => setEditing(true),
                },
                {
                  when: !isEditing && series.series_active === 0,
                  icon: RestoreFromTrashRoundedIcon,
                  balloonLabel: "Restore content",
                  onClick: () => setRestoring(true),
                },
                {
                  when: !isEditing && series.series_active === 1,
                  icon: DeleteForeverRoundedIcon,
                  balloonLabel: "Mark as inactive",
                  onClick: () => setTrashing(true),
                },
              ]}
            />
          )}
        </div>
        <div className="gw gw--center">
          <div className="g g--1-of-2">
            <ContentCard>
              <h3 className="content-page__sub-title">Short Summary</h3>
              {isEditing ? (
                <textarea
                  value={data.summary?.short_summary ?? "None"}
                  onChange={updateSummary.bind(null, "short_summary")}
                />
              ) : (
                <p className="content-page__text">{series.summary?.short_summary || "None"}</p>
              )}
              <h3 className="content-page__sub-title">Long Summary</h3>
              {isEditing ? (
                <textarea
                  value={data.summary?.long_summary ?? "None"}
                  onChange={updateSummary.bind(null, "long_summary")}
                />
              ) : (
                <div className="content-page__text">{series.summary?.long_summary || "None"}</div>
              )}
              <h3 className="content-page__sub-title">Country of Origin</h3>
              {isEditing ? (
                <input
                  type="text"
                  name="country"
                  value={data.country ?? "N/A"}
                  onChange={(e) => updateForm({ country: e.target.value })}
                ></input>
              ) : (
                <div className="content-page__text">{series.title?.country_of_origin || "None given"}</div>
              )}
              <h3 className="content-page__sub-title">Keywords</h3>
              <EditablePillList
                list={data.keywords?.map(({ keyword }) => keyword)}
                onRemove={onKeywordRemove}
                onAdd={() => setShowKeywordDialog(true)}
                editing={isEditing}
                emptyMessage="No keywords saved"
              />
            </ContentCard>
          </div>
          <div className="g g--1-of-2">
            <div className="u-margin--block-end">
              <ContentCard>
                <div className="content-page__section">
                  <div className="content-page__list">
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">Seasons</div>
                      <div className="content-page__list__value">{series.season_count ?? 0}</div>
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">Total Episodes</div>
                      <div className="content-page__list__value">{series.episode_count ?? 0}</div>
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">IMDb Genre</div>
                      {isEditing ? (
                        <Select
                          placeholder="Select a Genre"
                          name="genre"
                          options={GENRE_OPTIONS.imdb}
                          value={getActiveGenre("imdb_genre")}
                          onChange={(input) => updateGenre("imdb_genre", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">
                          {series.genre?.imdb?.imdb_genre?.label ?? "N/A"}
                        </div>
                      )}
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">IMDb Subgenre</div>
                      {isEditing ? (
                        <Select
                          placeholder="Select a Genre"
                          name="subgenre"
                          options={getSubgenresForGenre(getActiveGenre("imdb_genre"))}
                          value={getActiveGenre("imdb_subgenre")}
                          onChange={(input) => updateGenre("imdb_subgenre", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">
                          {series.genre?.imdb?.imdb_subgenre?.label ?? "N/A"}
                        </div>
                      )}
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">Freeview Genre</div>
                      {isEditing ? (
                        <Select
                          placeholder="Select a Genre"
                          name="genre"
                          options={GENRE_OPTIONS.freeview}
                          value={getActiveGenre("freeview_genre")}
                          onChange={(input) => updateGenre("freeview_genre", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">
                          {series.genre?.freeview?.freeview_genre?.label ?? "N/A"}
                        </div>
                      )}
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">Us TV Rating</div>
                      {isEditing ? (
                        <Select
                          options={RATING_OPTIONS.us_tv}
                          placeholder="Select a Rating"
                          name="us_tv_rating"
                          value={getActiveRating("us_tv")}
                          onChange={(input) => updateRating("us_tv", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">{series.rating?.us_tv_rating ?? "N/A"}</div>
                      )}
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">MPAA Rating</div>
                      {isEditing ? (
                        <Select
                          options={RATING_OPTIONS.mpaa}
                          placeholder="Select a Rating"
                          name="mpaa_rating"
                          value={getActiveRating("mpaa")}
                          onChange={(input) => updateRating("mpaa", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">{series.rating?.mpaa_rating ?? "N/A"}</div>
                      )}
                    </div>
                    <div className="content-page__list__item">
                      <div className="content-page__list__label">BBFC Rating</div>
                      {isEditing ? (
                        <Select
                          options={RATING_OPTIONS.bbfc}
                          placeholder="Select a Rating"
                          name="bbfc_rating"
                          value={getActiveRating("bbfc")}
                          onChange={(input) => updateRating("bbfc", input)}
                          classNamePrefix="react-select"
                          containerClass="react-select-container--small react-select-container--light"
                          menuPlacement="auto"
                        />
                      ) : (
                        <div className="content-page__list__value">{series.rating?.bbfc_rating ?? "N/A"}</div>
                      )}
                    </div>
                  </div>
                </div>
              </ContentCard>
            </div>
            <div>
              <ContentCard>
                <div className="content-page__sub-title">
                  Cast & Crew
                  {isEditing ? (
                    <button className="btn btn--icon" onClick={openCastCrewDialog}>
                      <AddCircleRoundedIcon />
                    </button>
                  ) : null}
                </div>
                {isEditing ? (
                  <div className="cast-crew">
                    {data.cast_crew?.map((person, index) => (
                      <div className="cast-crew__item" key={index}>
                        <div className="cast-crew__role">
                          <input
                            type="text"
                            onChange={(e) => updateCastCrew("role", e.target.value, index)}
                            value={person.role}
                          />
                        </div>
                        <div className="cast-crew__name">
                          <input
                            type="text"
                            onChange={(e) => updateCastCrew("name", e.target.value, index)}
                            value={person.name}
                          />
                        </div>
                        <div className="cast-crew__actions">
                          <button className="cast-crew__action-btn" onClick={() => removeCastCrew(index)}>
                            <CancelRoundedIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : series.cast_crew?.length ? (
                  <div className="content-page__list">
                    {series.cast_crew.map((person, index) => (
                      <div className="content-page__list__item" key={index}>
                        <div className="content-page__list__label">{person.role}</div>
                        <div className="content-page__list__value">{person.name}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  "N/A"
                )}
              </ContentCard>
            </div>
          </div>
        </div>
        <DialogForm
          isOpen={showCastCrewDialog}
          onClose={closeCastCrewDialog}
          onCancel={closeCastCrewDialog}
          onSubmit={addCastCrew}
          title="Add Cast / Crew"
          fields={[
            {
              label: "Role",
              type: "text",
              name: "role",
            },
            {
              label: "Name",
              type: "text",
              name: "name",
            },
          ]}
        />
        <DialogForm
          isOpen={showKeywordDialog}
          onSubmit={addKeyword}
          onClose={() => setShowKeywordDialog(false)}
          onCancel={() => setShowKeywordDialog(false)}
          title="Add new Keyword"
          body="Complete the form below to add a new keyword to this content"
          fields={[{ label: "Keyword", type: "text", name: "keyword", defaultValue: "" }]}
          key={`add-dialog__${uuid()}`}
        />
        <GenericDeleteDialog
          isOpen={isTrashing}
          body={
            "This will mark this series and all of it's related content as inactive, are you sure you wish to continue?"
          }
          onClose={() => {
            setTrashing(false);
          }}
          endpoint={`/api/series/${series.series_guid}`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setTrashing(false);
              toast.success("Successfully marked this Series as inactive.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
        <GenericRestoreDialog
          isOpen={isRestoring}
          body={"This will restore this series and some of its related data. Do you wish to continue?"}
          onClose={() => {
            setRestoring(false);
          }}
          endpoint={`/api/series/${series.series_guid}/restore`}
          onComplete={(result, error) => {
            if (error) {
              console.error(error);
            } else if (result) {
              setRestoring(false);
              toast.success("Successfully restored this series.");
              setTimeout(() => navigate(0), 1500);
            }
          }}
        />
      </React.Fragment>
    )
  );
}
