import axios from "./axios.js";
import fetcher from "../common/fetcher.js";
import format from "date-fns/format";

export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

function constructUri(
  base,
  {
    provider,
    search,
    pageSize = 50,
    genres = [],
    subgenres = [],
    ratings = [],
    seriesGuid,
    seasonGuid,
    minDuration,
    maxDuration,
    sorting,
    planDate,
    displayInactive,
    channelId,
  },
) {
  let genreQuery = "";
  let ratingQuery = "";
  let sortingQuery = "";
  if (genres.length) {
    let encodedArray = encodeURIComponent(JSON.stringify(genres));
    genreQuery = `&genres=${encodedArray}`;

    if (subgenres.length) {
      let encodedArray = encodeURIComponent(JSON.stringify(subgenres));
      genreQuery += `&subgenres=${encodedArray}`;
    }
  }
  if (ratings.length) {
    const ratingsObject = ratings.reduce(
      (prev, current) => {
        const schemeParts = current.split("_");
        const rated = schemeParts.pop();
        const scheme = schemeParts.join("_");
        return {
          ...prev,
          [scheme]: [...prev[scheme], rated],
        };
      },
      {
        mpaa: [],
        us_tv: [],
        bbfc: [],
      },
    );

    // parts
    let encodedArray = null;
    if (ratingsObject.mpaa.length) {
      encodedArray = encodeURIComponent(JSON.stringify(ratingsObject.mpaa));
      ratingQuery += `&ratings.mpaa=${encodedArray}`;
    }

    if (ratingsObject.bbfc.length) {
      encodedArray = encodeURIComponent(JSON.stringify(ratingsObject.bbfc));
      ratingQuery += `&ratings.bbfc=${encodedArray}`;
    }

    if (ratingsObject.us_tv.length) {
      encodedArray = encodeURIComponent(JSON.stringify(ratingsObject.us_tv));
      ratingQuery += `&ratings.us_tv=${encodedArray}`;
    }
  }

  if (sorting.length) {
    let encodedArray = encodeURIComponent(JSON.stringify(sorting));
    sortingQuery = `&sorting=${encodedArray}`;
  }

  let planQuery = planDate ? `&relative_date=${planDate}` : "";
  if (channelId) {
    planQuery += `&channel_id=${channelId}`;
  }

  return `${BASE_URL}/api/providers/${provider}/${base}?page_size=${pageSize}&duration=true${
    minDuration ? `&min_duration=${minDuration}` : ""
  }${maxDuration ? `&max_duration=${maxDuration}` : ""}${search ? `&search=${search}` : ""}${
    seriesGuid ? `&series_guid=${seriesGuid}` : ""
  }${
    seriesGuid && seasonGuid ? `&season_guid=${seasonGuid}` : ""
  }${genreQuery}${ratingQuery}${sortingQuery}${planQuery}${displayInactive ? "&active=all" : ""}`;
}

export async function getRequest(
  base,
  {
    provider,
    search,
    pageSize = 50,
    genres = [],
    subgenres = [],
    ratings = [],
    seriesGuid,
    seasonGuid,
    minDuration,
    maxDuration,
    sorting,
    planDate,
    displayInactive,
    channelId,
  },
) {
  const uri = constructUri(base, {
    provider,
    search,
    pageSize,
    genres,
    subgenres,
    ratings,
    seriesGuid,
    seasonGuid,
    minDuration,
    maxDuration,
    sorting,
    planDate,
    displayInactive,
    channelId,
  });
  const data = await fetcher(uri);
  return { ...data, uri };
}

export async function getContent({
  content,
  search,
  provider,
  pageSize = 50,
  genres = [],
  subgenres = [],
  ratings = [],
  seriesGuid,
  seasonGuid,
  minDuration,
  maxDuration,
  sorting,
  planDate,
  displayInactive,
  channelId = null,
}) {
  const payload = {
    provider,
    search,
    pageSize,
    genres,
    subgenres,
    ratings,
    seriesGuid,
    seasonGuid,
    minDuration,
    maxDuration,
    sorting,
    planDate,
    displayInactive,
    channelId,
  };
  switch (content) {
    case "features":
      return await getRequest("features", payload);
    case "promos":
      return await getRequest("promos", payload);
    case "episodes":
      return await getRequest("episodes", payload);
    case "series":
      return await getRequest("series", payload);

    default:
      throw Error("Unknown content type requested", content);
  }
}

export async function getContentFromPaginationUrl(url) {
  const data = await fetcher(url);
  return { ...data, uri: url };
}

export async function getAsset(id) {
  const data = await fetcher(`${BASE_URL}/api/assets/${id}`);
  return data;
}

export async function getGenres() {
  const data = await fetcher(`${BASE_URL}/api/genres`);
  return data;
}

export async function getRatings() {
  const data = await fetcher(`${BASE_URL}/api/ratings`);
  return data;
}

export async function getSeries(seriesGuid, extraFields = []) {
  const data = await fetcher(
    `${BASE_URL}/api/series/${seriesGuid}${extraFields?.length ? "?additional=" + extraFields.join(",") : ""}`,
  );
  return data;
}

export async function getSeriesForProvider(providerGuid) {
  const data = await fetcher(`${BASE_URL}/api/providers/${providerGuid}/series`);
  return data;
}

export async function getSeasonsForSeries(series_guid, provider_guid = null) {
  if (provider_guid) {
    const data = await fetcher(`${BASE_URL}/api/providers/${provider_guid}/series/${series_guid}/seasons`);
    return data;
  }

  const data = await fetcher(`${BASE_URL}/api/series/${series_guid}/seasons`);
  return data;
}

export async function getSeasonforSeries(series_guid, season_guid) {
  const data = await fetcher(`${BASE_URL}/api/series/${series_guid}/seasons/${season_guid}`);
  return data;
}

export async function getEpisodesForSeason(series_guid, season_guid) {
  const data = await fetcher(`${BASE_URL}/api/series/${series_guid}/seasons/${season_guid}/episodes`);
  return data;
}

export async function getAllProviders(withProviders = "") {
  const data = await fetcher(`${BASE_URL}/api/providers${withProviders === "all" ? "?active=all" : ""}`);
  return data;
}

export async function createDistribution(providerId) {
  const data = await axios.post(`${BASE_URL}/api/providers/${providerId}/distribution`, {});
  return data;
}

export async function deleteDistribution(providerId) {
  const response = await axios.delete(`${BASE_URL}/api/providers/${providerId}/distribution`);
  return response;
}

export async function getUser(userGuid) {
  const data = await fetcher(`${BASE_URL}/api/users/${userGuid}`);
  return data;
}

export async function getAllRoles() {
  const data = await fetcher(`${BASE_URL}/api/roles`);
  return data;
}

export async function getAllOrganisations() {
  const data = await fetcher(`${BASE_URL}/api/organisations`);
  return data;
}

export async function updateChannelRequest(channelId, payload) {
  const response = await axios.post(`${BASE_URL}/api/channels/${channelId}`, payload);
  return response;
}

export async function addChannelPlanProgram({ channelId, planId, program }) {
  const response = axios.post(`${BASE_URL}/api/channels/${channelId}/plans/${planId}`, { program });
  return response;
}

export async function updateChannelPlanBreaks({ channelId, planId, planBreaks }) {
  const response = await axios.post(`${BASE_URL}/api/channels/${channelId}/plans/${planId}`, {
    plan_breaks: planBreaks,
  });
  return response.data;
}

export async function updatePassword(userGuid, payload) {
  const response = await axios.post(`${BASE_URL}/api/users/${userGuid}/password`, payload);
  return response;
}

export async function updateSeries(series_guid, payload) {
  const response = await axios.patch(`${BASE_URL}/api/series/${series_guid}`, payload);
  return response;
}

export async function updateTitle(title_guid, payload) {
  const response = await axios.patch(`${BASE_URL}/api/titles/${title_guid}?additional=assets`, payload);
  return response;
}

export async function getFeature(featureGuid, extraFields = []) {
  const response = await fetcher(
    `${BASE_URL}/api/features/${featureGuid}${extraFields?.length ? "?additional=" + extraFields.join(",") : ""}`,
  );
  return response;
}

export async function getPromo(promoGuid, extraFields = []) {
  const response = await fetcher(
    `${BASE_URL}/api/promos/${promoGuid}${extraFields?.length ? "?additional=" + extraFields.join(",") : ""}`,
  );
  return response;
}

export async function getEpisode(episodeGuid, extraFields = []) {
  const response = await fetcher(
    `${BASE_URL}/api/episodes/${episodeGuid}${extraFields?.length ? "?additional=" + extraFields.join(",") : ""}`,
  );
  return response;
}

export async function getTitle(titleGuid) {
  const response = await fetcher(`${BASE_URL}/api/titles/${titleGuid}`);
  return response;
}

export async function getAssets(contentType, guid) {
  let response;

  switch (contentType) {
    case "title":
      response = await fetcher(`${BASE_URL}/api/titles/${guid}/assets`);
      break;
  }

  return response;
}

export async function getContentByType(type, guid, extraFields = []) {
  switch (type) {
    case "feature":
      return await getFeature(guid, extraFields);
    case "promo":
      return await getPromo(guid, extraFields);
    case "episode":
      return await getEpisode(guid, extraFields);
    default:
      throw Error(`Unknown content type requested [${type}] with guid [${guid}]`);
  }
}

export async function getDashVideos() {
  const response = await axios.get(`${BASE_URL}/api/assets/content_videos/dash`);
  return response;
}

export async function addUser(payload) {
  const response = await axios.post(`${BASE_URL}/api/users`, payload);
  return response;
}

export async function deleteUser(userGuid) {
  const response = await axios.delete(`${BASE_URL}/api/users/${userGuid}`);
  return response;
}

export async function restoreUser(userGuid) {
  const response = await axios.post(`${BASE_URL}/api/users/${userGuid}/restore`);
  return response;
}

export async function updateUser(userGuid, payload) {
  const response = await axios.patch(`${BASE_URL}/api/users/${userGuid}`, payload);
  return response;
}

export async function login(payload) {
  const response = await axios.post(`${BASE_URL}/api/auth/login`, payload);
  return response;
}

export async function getChannel(id) {
  const channel = await fetcher(`${BASE_URL}/api/channels/${id}`);
  return channel;
}

export async function getPlan(id, date) {
  const plan = await fetcher(`${BASE_URL}/api/channels/${id}/plans?plan_date=${format(date, "Y-MM-dd")}`);
  if (!plan) {
    return null;
  }
  return plan;
}

export async function getChannelPlanDirect(channelId, planDate) {
  const channelPlans = await fetcher(`${BASE_URL}/feeds/channels/${channelId}?plans=all&plan_date=${planDate}`);
  return channelPlans;
}

export async function getAllIngests() {
  const ingests = await fetcher(`${BASE_URL}/api/feeds/ingests`);
  return ingests;
}

export async function addIngest(payload) {
  const response = await axios.post(`${BASE_URL}/api/feeds/ingests`, payload);
  return response;
}

export async function findIngestFeed(ingestFeedId) {
  const response = await axios.get(`${BASE_URL}/api/feeds/${ingestFeedId}`);
  return response;
}

export async function getIngestForFeed(feedGuid, ingestGuid) {
  const response = await axios.get(`${BASE_URL}/api/feeds/${feedGuid}/ingests/${ingestGuid}`);
  return response;
}

export async function getIngestResultChildren(feedGuid, ingestGuid, ingestResultId) {
  const response = await axios.get(`${BASE_URL}/api/feeds/${feedGuid}/ingests/${ingestGuid}/results/${ingestResultId}`);
  return response;
}

export async function getAllPlayouts() {
  const data = await fetcher(`${BASE_URL}/api/playouts`);
  return data;
}

export async function clearPlayoutFromProgram(playoutId, playoutProgramId) {
  const response = await axios.delete(
    `${BASE_URL}/api/playouts/${playoutId}/programs?after_program=${playoutProgramId}`,
  );
  return response;
}

export async function scheduleProgramsForChannel(playoutId) {
  const response = await axios.post(`${BASE_URL}/api/playouts/${playoutId}/schedule`);
  return response;
}

export async function scheduleProgram(playoutId, programId) {
  const response = await axios.post(`${BASE_URL}/api/playouts/${playoutId}/programs/${programId}/schedule`);
  return response;
}

export async function triggerIngestion(feedId) {
  const response = await axios.post(`${BASE_URL}/api/feeds/${feedId}/ingests`);
  return response;
}

export async function createPromo(payload) {
  const data = await axios.post(`${BASE_URL}/api/promos`, payload);
  return data;
}

export async function copyPlaylistVersion(playlistGuid, version) {
  const data = await axios.post(`${BASE_URL}/api/avod-playlists/${playlistGuid}/versions/${version}/copy`, {});
  return data;
}

export async function getContentPoster(providerId, linkType, linkGuid) {
  const data = await fetcher(`${BASE_URL}/api/providers/${providerId}/${linkType}/${linkGuid}/poster`);
  return data;
}
