import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SchedulerProvider from "../providers/scheduler-context";
import axios from "../requests/axios";
import SimpleChannelHeading from "./epg-editor/components/simple-channel-heading";
import {
  format,
  setHours,
  setMinutes,
  addMinutes,
  startOfDay,
  differenceInSeconds,
  compareAsc,
  addDays,
  setSeconds,
  addHours,
} from "date-fns";
import { DragDropContext } from "react-beautiful-dnd";
import VerticalScheduler from "../components/vertical-scheduler/vertical-scheduler";
import { planToEpg } from "./epg-editor/utils/epg-transformers";
import ContentCard from "./library/components/content-card";
import Loader from "../common/loader";
import dateIsBetween from "../common/date-is-between";
import EpgDndHelper from "./epg-editor/utils/dnd-helpers.js";

const SCHEDULER_WIDTH = 600;
const TOTAL_PLANS_COUNT = 10;

function SchedulerWeeklyPage() {
  const [plans, setPlans] = React.useState([]);
  const [channel, setChannel] = React.useState({});
  const [timezone, setTimezone] = React.useState(window.localStorage.getItem("__gstv_timezone") ?? "Europe/London");

  const { channelGuid } = useParams();
  const scrollRef = useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    const baseDate = new Date();
    [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6].forEach((i) => {
      axios
        .get(`api/channels/${channelGuid}/plans?plan_date=${format(addDays(baseDate, i), "yyyy-MM-dd")}`)
        .then((resp) => {
          const response = resp.data;
          response.programs.forEach((planProgram) => {
            planProgram.program_start = fromUtcDate(planProgram.program_start);
            planProgram.program_end = fromUtcDate(planProgram.program_end);
            return planProgram;
          });

          const referenceDate = startOfDay(new Date(response.plan_date));
          const breaks = response.plan_breaks
            .map((planBreak) => {
              const [hours, minutes] = planBreak.start.split(":");
              const [endHours, endMinutes] = planBreak.end.split(":");
              let start = addHours(addMinutes(referenceDate, minutes), hours);
              let end = addHours(addMinutes(referenceDate, endMinutes), endHours);

              return {
                ...planBreak,
                start,
                end,
                duration: differenceInSeconds(end, start),
              };
            })
            .sort((a, b) => {
              return compareAsc(a.start, b.start);
            });

          const channelEpg = planToEpg(channel, response);
          const dndHelper = EpgDndHelper(channel.channel_id, new Date(response.plan_date), breaks);
          channelEpg.epg = dndHelper.recalculate(channelEpg.epg);

          const plan = {
            ...channelEpg,
            plan_breaks: breaks,
            plan_date: response.plan_date,
          };

          setPlans((prev) =>
            [...prev, plan].sort((a, b) => {
              return compareAsc(new Date(a.plan_date), new Date(b.plan_date));
            }),
          );
        });
    });
  }, [channelGuid]);

  React.useLayoutEffect(() => {
    // once schedules are loaded, scroll to today's schedule
    if (plans.length === TOTAL_PLANS_COUNT && scrollRef.current) {
      scrollRef.current.scrollLeft = 3 * SCHEDULER_WIDTH;
    }
  }, [plans.length]);

  React.useEffect(() => {
    axios.get(`api/channels/${channelGuid}`).then((resp) => setChannel(resp.data));
  }, [channelGuid]);

  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  function toggleTimezone() {
    const nextTimezone = timezone === "UTC" ? "Europe/London" : "UTC";

    setTimezone(nextTimezone);
    window.localStorage.setItem("__gstv_timezone", nextTimezone);
  }

  function onCalendarDateChange(input) {
    navigate(`/scheduler/${channelGuid}/${format(input, "y-MM-dd")}`);
  }

  function getSegmentIndexWithinTime(plan, time) {
    return plan.plan_breaks.findIndex((s) => dateIsBetween(time, s.start, s.end, "[)"));
  }

  function onDragEnd(args) {
    let { destination, source } = args;

    if (!destination) {
      return;
    }

    const planIndex = destination.droppableId.split("-")[0];
    const plan = plans[planIndex];

    if (source.droppableId.split("-")[0] !== destination.droppableId.split("-")[0]) {
      return;
    }

    destination.droppableId = destination.droppableId.split("-")[1];
    source.droppableId = source.droppableId.split("-")[1];

    const dndHelper = EpgDndHelper(channel.channel_id, new Date(plan.plan_date), plan.plan_breaks);
    let currentBreakStartTime = null;
    let currentBreakEndTime = null;
    let sourceSegment = null;

    if (destination.droppableId.includes(":")) {
      const breakTime = destination.droppableId.split("-").slice(-1)[0];
      const [hours, minutes] = breakTime.split(":");
      currentBreakStartTime = setHours(setMinutes(setSeconds(new Date(plan.plan_date), 0), minutes), hours);
    }

    if (source.droppableId.includes(":")) {
      const breakTime = source.droppableId.split("-").slice(-1)[0];
      const [hours, minutes] = breakTime.split(":");
      sourceSegment = setHours(setMinutes(setSeconds(new Date(plan.plan_date), 0), minutes), hours);
    }

    let list = JSON.parse(JSON.stringify(plan.epg)).map((item) => ({
      ...item,
      since: new Date(item.since),
      till: new Date(item.till),
    }));

    let absoluteDestinationIndex = destination.index;
    let absoluteSourceIndex = source.index;
    if (currentBreakStartTime) {
      // indices are relative to the segment they are in, we need the absolute index from here on
      const indexedCounter = [];
      for (let i = 0; i < list.length; i++) {
        const currIndex = getSegmentIndexWithinTime(plan, list[i].since);
        if (indexedCounter[currIndex]) {
          indexedCounter[currIndex] += 1;
        } else {
          indexedCounter[currIndex] = 1;
        }
      }
      const toSegmentIndex = getSegmentIndexWithinTime(plan, currentBreakStartTime);
      let fromSegmentIndex = sourceSegment ? getSegmentIndexWithinTime(plan, sourceSegment) : null;
      absoluteDestinationIndex += indexedCounter.slice(0, toSegmentIndex).reduce((prev, curr) => prev + curr, 0);
      if (fromSegmentIndex !== null && fromSegmentIndex < toSegmentIndex) {
        // we have for sure already counted this element
        absoluteDestinationIndex--;
      }

      currentBreakEndTime = plan.plan_breaks[toSegmentIndex].end;

      fromSegmentIndex = getSegmentIndexWithinTime(plan, sourceSegment);
      absoluteSourceIndex += indexedCounter.slice(0, fromSegmentIndex).reduce((prev, curr) => prev + curr, 0);
    }

    list = dndHelper.move(
      list,
      absoluteSourceIndex,
      absoluteDestinationIndex,
      currentBreakStartTime,
      currentBreakEndTime,
    );

    setPlans((prev) => {
      return prev.map((p, i) => {
        if (i == planIndex) {
          // DO NOT CHANGE
          p.epg = list;
        }

        return p;
      });
    });
  }

  return (
    <SchedulerProvider timezone={timezone}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="spread-container">
          <div className="spread-container__top">
            <SimpleChannelHeading
              channelName={channel.display_name || "Loading channel..."}
              planDate={new Date()}
              changePlanDate={onCalendarDateChange}
              toggleTimezone={toggleTimezone}
            />
          </div>
          <div
            className="spread-container__middle spread-container__middle--columns"
            style={{ display: "flex", maxWidth: "100%", overflowX: "scroll", scrollBehavior: "smooth" }}
            ref={scrollRef}
          >
            {plans.length === TOTAL_PLANS_COUNT ? (
              plans.map((plan, index) => (
                <div key={index} style={{ width: SCHEDULER_WIDTH }}>
                  <div className="weekly-scheduler__header">
                    <div
                      className="weekly-scheduler__header__label"
                      onClick={() => navigate(`/scheduler/${channelGuid}/${plan.plan_date}`)}
                    >
                      {plan ? plan.plan_date : ""}
                    </div>
                  </div>

                  <ContentCard noPadding>
                    <VerticalScheduler
                      epg={plan.epg}
                      droppableId={index}
                      planDate={new Date(plan.plan_date)}
                      planBreaks={plan.plan_breaks}
                      addPlanBreak={() => {}}
                      deletePlanBreak={() => {}}
                      selectProgram={() => {}}
                      onPlay={() => true}
                      removeProgram={() => {}}
                      copySegment={() => {}}
                      updateProgramDuration={() => {}}
                      customRange={[]}
                      setCustomTimeRange={() => {}}
                      dropsDisabled={false}
                      onProgramDrag={() => {}}
                    />
                  </ContentCard>
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </DragDropContext>
    </SchedulerProvider>
  );
}

export default SchedulerWeeklyPage;
